$(function(){

	let question = 1;

	//кнопка назад
    $(".kalk6 .previous").on('click',  function(){

        $(".kalk6 .question"+question).css('display','none');
        question = question - 1;
        $(".kalk6 .question"+question).css('display','block');

    }); //end on

    //выбор ответа пользователем - записываем ответы в скрытые инпуты
    $(".kalk6 .col").on('click',  function(){

    	let job = $(this).children(".job").html();

        $(".kalk6 .question5 .q_"+question).val(job);

        $(".kalk6 .question"+question).css('display','none');
        question = question + 1;
        $(".kalk6 .question"+question).css('display','block');

    }); //end on

    //кнопка узнать стоимость
    $(".kalk6 .sent").on('click',  function(){

    	let name = $(".kalk6 .question5 input[name=name]").val();
    	let phonenumber = $(".kalk6 .question5 input[name=phonenumber]").val();
        let mail = $(".kalk6 .question5 input[name=mail]").val();

    	if ((name.length<1)||(phonenumber.length<1)||(mail.length<1)){
    		event.preventDefault();
    		if((name.length<1)&&(phonenumber.length<1)&&(mail.length<1)){
    			$(".kalk6 .question5 input[name=name]").css('background-color','#FDE0DD');
    			$(".kalk6 .question5 input[name=phonenumber]").css('background-color','#FDE0DD');
                $(".kalk6 .question5 input[name=mail]").css('background-color','#FDE0DD');
    		}else if ((name.length<1)&&(phonenumber.length<1)){
    			$(".kalk6 .question5 input[name=name]").css('background-color','#FDE0DD');
                $(".kalk6 .question5 input[name=phonenumber]").css('background-color','#FDE0DD');
                $(".kalk6 .question5 input[name=mail]").css('background-color','#fff');
    		}else if ((phonenumber.length<1)&&(mail.length<1)){
                $(".kalk6 .question5 input[name=mail]").css('background-color','#FDE0DD');
                $(".kalk6 .question5 input[name=phonenumber]").css('background-color','#FDE0DD');
                $(".kalk6 .question5 input[name=name]").css('background-color','#fff');
    		}else if ((name.length<1)&&(mail.length<1)){
                $(".kalk6 .question5 input[name=mail]").css('background-color','#FDE0DD');
                $(".kalk6 .question5 input[name=name]").css('background-color','#FDE0DD');
                $(".kalk6 .question5 input[name=phonenumber]").css('background-color','#fff');
            }else if (name.length<1){
                $(".kalk6 .question5 input[name=name]").css('background-color','#FDE0DD');
                $(".kalk6 .question5 input[name=mail]").css('background-color','#fff');
                $(".kalk6 .question5 input[name=phonenumber]").css('background-color','#fff');
            }else if (mail.length<1){
                $(".kalk6 .question5 input[name=mail]").css('background-color','#FDE0DD');
                $(".kalk6 .question5 input[name=name]").css('background-color','#fff');
                $(".kalk6 .question5 input[name=phonenumber]").css('background-color','#fff');
            }else if (phonenumber.length<1){
                $(".kalk6 .question5 input[name=phonenumber]").css('background-color','#FDE0DD');
                $(".kalk6 .question5 input[name=mail]").css('background-color','#fff');
                $(".kalk6 .question5 input[name=name]").css('background-color','#fff');
            }
    	}else{

            sendAjaxForm('result_form', 'modal_form', '/php/mail.php');

    	}
    	
    }); //end on
});//end ready

function sendAjaxForm(result_form, ajax_form, url) {
  $.ajax({
    url: url,
    type: "POST",
    dataType: "html",
    data: $("#" + ajax_form).serialize(),
    success: function success(response) {
      var result = $.parseJSON(response);
      if (result.name!=0){
        alert(result.name + ', спасибо за Ваш интерес. В ближайшее время с Вами свяжутся.');
      }else{
         alert('Ошибка. Данные не отправлены');
      }   
    },
    error: function error(response) {
      alert('Ошибка. Данные не отправлены');
    }
  });
}
